.navbar {
    padding: 0;
}
.navbarNav a {
    font-size: 18px;
    font-weight: 500;
    color: #2C3E50;
    text-decoration: none;
    padding: 8px 12px;
}
.navbarNav a:hover {
    color: #8A2BE2;
    background-color: #eee;
}
