.testimonialCard {
    transition: 0.4s;
}
.testimonialCard:hover {
    color: #333;
    background-color: #f5f5f5;
}
.testimonialTitle {
    height: 32px;
}
.testimonialComment {
    min-height: 120px;
}
.testimonialRating {
    margin: 0 auto;
    height: 24px;
}